import React from "react";
import { Box, Grid, useTheme } from "@mui/material";
import { keyframes } from "@emotion/react";
import DEUTSCHESTARTUPS from '../../assets/images/pressRelease/logosSLIDER/deutscheStartups.jpg'
import FAZ from '../../assets/images/pressRelease/logosSLIDER/FAZ.jpg'
import FAZINSTITUTE from '../../assets/images/pressRelease/logosSLIDER/faz_institut.jpg'
import FIRMENPRESSE from '../../assets/images/pressRelease/logosSLIDER/firmenpresse.jpg'
import FUNDSZENE from '../../assets/images/pressRelease/logosSLIDER/fundszene.jpg'
import GRUENDERMETROPOLE from '../../assets/images/pressRelease/logosSLIDER/Gruendermetropole.jpg'
import INGENIEURDE from '../../assets/images/pressRelease/logosSLIDER/ingenieur_de.jpg'
import KARRIEREFUEHRER from '../../assets/images/pressRelease/logosSLIDER/karrierefuehrer.jpg'
import PERSONALWIRTSCHAFT from '../../assets/images/pressRelease/logosSLIDER/personalwirtschaft.jpg'
import PORTALDERWIRTSCHAFT from '../../assets/images/pressRelease/logosSLIDER/portalderwirtschaft.jpg'
import SHECONOMY from '../../assets/images/pressRelease/logosSLIDER/sheconomy.jpg'
import STARTUPVALLEY from '../../assets/images/pressRelease/logosSLIDER/startup_valley.jpg'
import STATION from '../../assets/images/pressRelease/logosSLIDER/station.jpg'

import variables from '../../settings/_variables.scss'
import { createTheme } from '@mui/material/styles';
const scrollAnimation = keyframes`
  0% { transform: translateX(0); }
  100% { transform: translateX(-100%); }
`;


const theme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1200,
        custom1280: 1280,
        custom1536: 1536,
        custom1680: 1680,
        custom1745: 1745,
        xl: 1920,
      },
    },
});

const AutoScrollCarousel = () => {
    const theme = useTheme();
    const partnerLogos = [
        {image: FAZ, link: 'https://www.faz.net/aktuell/rhein-main/wirtschaft/fuehrungskraefte-was-frauen-sich-wuenschen-110123821.html'},
        {image: SHECONOMY, link: 'https://sheconomy.media/vorurteile-in-der-vorstandsetage-weibliche-fuehrungskraefte-werden-unterschaetzt/'},
        {image: KARRIEREFUEHRER, link: 'https://www.karrierefuehrer.de/e-paper/frauen-in-fuehrungspositionen-2024-2025-weg-mit-den-bremskloetzen.html'},
        {image: GRUENDERMETROPOLE, link: 'https://gruendermetropole-berlin.de/bunton-schliesst-erfolgreich-erste-finanzierungsrunde-ab'},
        {image: PORTALDERWIRTSCHAFT, link: 'https://www.portalderwirtschaft.de/pressemitteilung/handel-wirtschaft-finanzen-banken-versicherungen/386320/besetzung-von-vorstands-positionen-voreingenommen-maennlich-dominiert.html'},
        {image: PERSONALWIRTSCHAFT, link: 'https://www.personalwirtschaft.de/news/recruiting/hr-start-up-bunton-sammelt-erstmals-geld-ein-170962'},
        {image: STARTUPVALLEY, link: 'https://startupvalley.news/de/bunton/'},
        {image: DEUTSCHESTARTUPS, link: 'https://www.deutsche-startups.de/2024/02/21/dealmonitor-fuchs-eule-threedy-altavo/'},
        {image: FAZINSTITUTE, link: 'https://www.faz-institut.de/'},
        {image: FIRMENPRESSE, link: 'https://www.firmenpresse.de/pressinfo2083706-bunton-schliesst-erfolgreich-erste-finanzierungsrunde-ab.html'},
        {image: FUNDSZENE, link: 'https://fundscene.com/bunton-beruft-weitere-beiratsmitglieder/%20Start%20Meldung%20bunton%20beruft%20weitere%20Beiratsmitglieder'},
        {image: INGENIEURDE, link: 'https://www.ingenieur.de/karriere/bewerbung/ki-basiertes-job-matching-wie-weibliche-fuehrungskraefte-an-die-jobs-kommen/'},
        {image: STATION, link: 'https://station-frankfurt.de/2024/02/22/ki-startup-bunton-schliesst-erfolgreich-erste-finanzierungsrunde-ab/'},
    ]

    const totalImages = [...partnerLogos, ...partnerLogos];
    return (
        <Box
        className='auto-scroll'
        sx={{
            overflow: "hidden",
            whiteSpace: "nowrap",
            width: "100%",
            position: "relative"
        }}
        >
        <Box
            sx={{
            display: "flex",
            animation: `${scrollAnimation} 20s linear infinite`,
            }}
        >
            {totalImages.map((item, index) => (
            <Grid item key={index} sx={{ flex: "0 0 auto" }}>
                <Box
                    component="a"
                    href={item.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    sx={{
                        display: 'inline-block',
                        textDecoration: 'none',
                    }}
                    >
                    <Box
                        component="img"
                        src={item.image}
                        alt={`image-${index}`}
                        sx={{
                            width: '100%',
                            height: '71%',
                            maxWidth: '157px',
                            maxHeight: '99px',
                            border: `2px solid rgb(188, 221, 210)`,
                            borderRadius: '30px',
                            background: variables.WHITE,
                            marginRight: '10px'
                        }}
                    />
                </Box>
            </Grid>
            ))}
        </Box>
        </Box>
    );
};

export default AutoScrollCarousel;
