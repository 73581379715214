import React, { useEffect } from "react";
import Layout from '../../template/Layout'
import { Box, Container, Grid, Typography} from '@mui/material'
import HeaderBanner from '../../assets/images/pressRelease/HEADER.webp'
import MobileHeaderBanner from '../../assets/images/pressRelease/bunton_header_mobile.jpg'
import PageMetaTags from '../../base/PageMetaTags'
import { useSelector } from 'react-redux'
import KeyFactsSection from './KeyFactsSection';
import ContactSection from './ContactSection';
import AutoScrollCarousel from './AutoScrollCarousel'
import BuntonArticles from './BuntonArticles'
import Announcements from './Announcements'
import DownloadLogo from './DownloadLogo'
import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1200,
        custom1280: 1280,
        custom1536: 1536,
        custom1680: 1680,
        custom1706: 1706,
        custom1745: 1745,
        xl: 1920,
      },
    },
  });

const PressRelease = () => {
    const { responsiveBreakpoint } = useSelector(state => state.initial)
    const { loginDetails, loginCheckAuthDetails } = useSelector(
        state => state.login
    )
    const userRole =
        loginCheckAuthDetails && loginCheckAuthDetails.userRole
            ? loginCheckAuthDetails.userRole
            : loginDetails && loginDetails.role
            ? loginDetails.role
            : null

    const adminStyles = userRole === "admin" ? { marginTop: '-40px' } : {};
    useEffect(() => {
        const body = document.querySelector('#root');
        body.scrollTo({
            top: 0,
            behavior: "auto",
        });
    }, []);
    return (
        <Layout
            containerSx={{
                mt: {
                    md: 11,
                    lg: 10
                },
                pt: 0
            }}
        >
            <PageMetaTags title='Press Release | Bunton' />
            <Container maxWidth='1520px' disableGutters sx={adminStyles}>
                <Box
                    sx={{
                        height: {
                            sm: '150px',
                            md: '193px',
                            lg: '350px'
                        },
                        [theme.breakpoints.only('lg')]: {
                            marginTop: '-106px'
                        },
                        [theme.breakpoints.only('custom1280')]: {
                            marginTop: '-99px'
                        },
                        [theme.breakpoints.only('custom1536')]: {
                            marginTop: '-75px'
                        },
                        [theme.breakpoints.only('custom1680')]: {
                            marginTop: '-62px'
                        },
                        [theme.breakpoints.only('custom1706')]: {
                            marginTop: '-60px'
                        },
                        [theme.breakpoints.only('custom1745')]: {
                            marginTop: '-58px'
                        }
                    }}
                    className="banner-image"
                >
                    <Box
                        component='img'
                        src={
                            ['mob'].includes(responsiveBreakpoint) ? MobileHeaderBanner : HeaderBanner
                        }
                        sx={{
                            width: '100%',
                            height: '100%',
                            maxHeight: '350px',
                            objectFit: 'cover',
                            [theme.breakpoints.only('lg')]: {
                                objectFit: 'contain',
                            },
                            [theme.breakpoints.only('custom1280')]: {
                                objectFit: 'contain',
                            },
                            [theme.breakpoints.only('custom1536')]: {
                                objectFit: 'contain',
                            },
                            [theme.breakpoints.only('custom1680')]: {
                                objectFit: 'contain',
                            },
                            [theme.breakpoints.only('custom1706')]: {
                                objectFit: 'contain',
                            },
                            [theme.breakpoints.only('custom1745')]: {
                                objectFit: 'contain',
                            },
                        }}
                    />
                </Box>

                <Box sx={{
                        my: 5,
                        [theme.breakpoints.only('lg')]: {
                            marginTop: '-23px'
                        },
                        [theme.breakpoints.only('custom1280')]: {
                            marginTop: '7px'
                        },
                        [theme.breakpoints.only('custom1536')]: {
                            marginTop: '19px'
                        }
                    }}
                    className='scrollable-images-container'>
                    <Box
                        sx={{              
                            display: "flex",
                            overflowX: "auto",
                            whiteSpace: "nowrap",
                            scrollbarWidth: "none",
                            "&::-webkit-scrollbar": {
                                display: "none",
                            },
                            "-ms-overflow-style": "none"
                        }}>
                        <AutoScrollCarousel />
                    </Box>
                </Box>
            </Container>
            { ['mob'].includes(responsiveBreakpoint) &&
                <Container
                    maxWidth={false}
                    disableGutters
                    sx={{
                        padding: {
                            xs: '0px 30px 30px 30px',
                            sm: '0px 80px 30px 80px',
                            md: '0px 80px 30px 80px',
                            lg: '0px 80px 30px 80px',
                            xl: '0px 80px 30px 80px'
                        }
                    }}
                >
                    <Grid container spacing={2}>
                        <Grid item xs={12} sx={{marginTop: '-14px'}}>
                            <KeyFactsSection />
                            <ContactSection />
                        </Grid>
                        <BuntonArticles />
                        <DownloadLogo />
                        <Announcements />
                    </Grid>

                    <Grid container spacing={2} sx={{ padding: 2 }}>
                        <Grid item xs={12}>
                            <Box>
                                <Typography variant='paragraph' className='font-family-poppins-regular'>
                                    Für weitere Informationen zu bunton, Bildmaterial oder Interviewanfragen stehen wir Ihnen jederzeit gerne zur Verfügung.
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            }
            { ['web'].includes(responsiveBreakpoint) &&
                <Container
                    maxWidth={false}
                    disableGutters
                    sx={{
                        padding: {
                            xs: '0px 30px 30px 30px',
                            sm: '0px 80px 30px 80px',
                            md: '0px 80px 30px 80px',
                            lg: '0px 80px 30px 80px',
                            xl: '0px 80px 30px 80px'
                        }
                    }}
                >
                    <Grid container spacing={2} sx={{ padding: 2 }}>
                        <BuntonArticles />
                        <Grid item xs={4} md={4} sx={{paddingLeft: '107px !important',  marginTop: '-14px', position: 'sticky', top: '56px', alignSelf: 'flex-start'}}>
                            <KeyFactsSection />
                            <ContactSection />
                        </Grid>
                        <DownloadLogo />
                        <Announcements />
                    </Grid>

                    <Grid container spacing={2} sx={{ padding: 2 }}>
                        <Grid item xs={8}>
                            <Box>
                                <Typography variant='paragraph' className='font-family-poppins-regular'>
                                    Für weitere Informationen zu bunton, Bildmaterial oder Interviewanfragen stehen wir Ihnen jederzeit gerne zur Verfügung.
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            }
            { ['tab'].includes(responsiveBreakpoint) &&
                <Container
                    maxWidth={false}
                    disableGutters
                    sx={{
                        padding: {
                            xs: '0px 30px 30px 30px',
                            sm: '0px 80px 30px 80px',
                            md: '0px 80px 30px 80px',
                            lg: '0px 80px 30px 80px',
                            xl: '0px 80px 30px 80px'
                        }
                    }}
                >
                    <Grid container spacing={2}>
                        <Grid item xs={6} md={6}>
                            <KeyFactsSection />
                        </Grid>
                        <Grid item xs={6} md={6}>
                            <ContactSection />
                        </Grid>
                        <BuntonArticles />
                        <DownloadLogo />
                        <Announcements />
                    </Grid>

                    <Grid container spacing={2} sx={{ padding: 2 }}>
                        <Grid item xs={12}>
                            <Box>
                                <Typography variant='paragraph' className='font-family-poppins-regular'>
                                    Für weitere Informationen zu bunton, Bildmaterial oder Interviewanfragen stehen wir Ihnen jederzeit gerne zur Verfügung.
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            }
        </Layout>
    )
}

export default PressRelease
