import * as React from 'react';
import { Typography, Box, Avatar, Grid } from '@mui/material';
import CONTACT from '../../assets/images/pressRelease/CONTACT.jpg'
import Divider from '@mui/material/Divider'
import { createTheme } from '@mui/material/styles';
import { useSelector } from 'react-redux'

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      custom1280: 1280,
      custom1536: 1536,
      custom1680: 1680,
      custom1745: 1745,
      xl: 1920,
    },
  },
});

const ContactSection = () => {
  const { responsiveBreakpoint } = useSelector(state => state.initial)

  return (
    <>
      { ['web'].includes(responsiveBreakpoint) &&
        <Grid container spacing={2} sx={{ padding: 2, marginTop: {lg: '37px', xl: '0'} }}>
          <Grid item xs={12} sx={{
            [theme.breakpoints.only('custom1536')]: {
              position: 'relative',
              bottom: '-28px'
            },
            [theme.breakpoints.only('custom1680')]: {
              position: 'relative',
              bottom: '-28px'
            },
            [theme.breakpoints.only('custom1745')]: {
              position: 'relative',
              bottom: '-28px'
            }
          }}>
            <div>
              <Typography variant="h3" sx={{fontWeight: 900, fontSize: '20px !important'}}>Kontakt</Typography>
              <Divider
                sx={{
                  mt: 2,
                  borderBottomWidth: 2,
                  borderColor: '#bcddd2',
                  marginRight: {xs: '-71px', xl: '0px'}
                }}
              />
              <Box sx={{position: 'relative', top: '20px' }}>
                  <Box sx={{ mb: 2, display: 'flex' }}>
                    <Box>
                      <Avatar src={CONTACT} alt='Bunton Contact' sx={{  width: '70px',  height: '70px', borderRadius: '40px' }}/>
                    </Box>
                    <Box sx={{ ml: 2, position: 'relative', top: '12px' }}>
                      <Typography sx={{fontWeight: 900}}>bunton GmbH</Typography>
                      <Typography variant="body2">welcome@bunton.de</Typography>
                    </Box>
                  </Box>
              </Box>
            </div>
          </Grid>
        </Grid>
      }
      { ['tab'].includes(responsiveBreakpoint) &&
        <div>
          <Typography variant="h3" sx={{fontWeight: 900, fontSize: '20px !important'}}>Kontakt</Typography>
          <Divider
            sx={{
              mt: 2,
              borderBottomWidth: 2,
              borderColor: '#bcddd2',
            }}
          />
          <Box sx={{position: 'relative', top: '20px' }}>
              <Box sx={{ mb: 2, display: 'flex' }}>
                <Box>
                  <Avatar src={CONTACT} alt='Bunton Contact' sx={{  width: '70px',  height: '70px', borderRadius: '40px' }}/>
                </Box>
                <Box sx={{ ml: 2, position: 'relative', top: '12px' }}>
                  <Typography sx={{fontWeight: 900}}>bunton GmbH</Typography>
                  <Typography variant="body2">welcome@bunton.de</Typography>
                </Box>
              </Box>
          </Box>
        </div>
      }
      { ['mob'].includes(responsiveBreakpoint) &&
        <Grid container spacing={2} sx={{ padding: 2, mt: 0 }}>
          <Grid item xs={12}>
            <div>
              <Typography variant="h3" sx={{fontWeight: 900, fontSize: '18px !important'}}>Kontakt</Typography>
              <Divider
                sx={{
                  mt: 2,
                  borderBottomWidth: 2,
                  borderColor: '#bcddd2',
                }}
              />
              <Box sx={{position: 'relative', top: '20px' }}>
                  <Box sx={{ mb: 2, display: 'flex' }}>
                    <Box>
                      <Avatar src={CONTACT} alt='Bunton Contact' sx={{  width: '70px',  height: '70px', borderRadius: '40px' }}/>
                    </Box>
                    <Box sx={{ ml: 2, position: 'relative', top: '12px' }}>
                      <Typography sx={{fontWeight: 900}}>bunton GmbH</Typography>
                      <Typography variant="body2">welcome@bunton.de</Typography>
                    </Box>
                  </Box>
              </Box>
            </div>
          </Grid>
        </Grid>
      }
    </>
  );
};

export default ContactSection;
