import * as React from 'react';
import { Box, Grid, Button} from '@mui/material'
import Arrow from './Icons/RightArrow.svg'
import Icons from '../../components/Icons'
import Divider from '@mui/material/Divider'
import { StyledPressReleaseDetails } from './pressStyles'
import { createTheme } from '@mui/material/styles';
import { useSelector } from 'react-redux'

const theme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1200,
        custom1279: 1279,
        custom1536: 1536,
        custom1681: 1681,
        custom1706: 1706,
        custom1746: 1746,
        xl: 1920,
      },
    },
});

const Announcements = () => {
    const { responsiveBreakpoint } = useSelector(state => state.initial)
    const announcements = [
        {
            date: '12.12.2024',
            text: '„Currywurst ist mein deutsches Lieblingsessen“',
            pdfFile: '/pressRelease/PDFS/PM_bunton_Currywurst ist mein deutsches Lieblingsessen_12.12.2024.pdf'
        },
        {
            date: '12.12.2024',
            text: 'Karriere-Plattform für weibliche Führungskräfte bunton und internationales Frauennetzwerk EWMD vereinbaren Kooperation',
            pdfFile: '/pressRelease/PDFS/PM_bunton_Frauennetzwerk_EWMD_und_Karriere-Plattform_fuer_weibliche_Fuehrungskraefte_bunton_vereinbaren_Kooperation_10.12.2024.pdf'
        },
        {
            date: '26.11.2024',
            text: 'Basierend auf 20 Interviews von Top-Führungskräften startet bunton eine repräsentative Befragung zu Erwartungen, Interessen und Herausforderungen von Frauen in Führungspositionen - Kooperation mit F.A.Z.-Institut',
            pdfFile: '/pressRelease/PDFS/PM_bunton_Befragung_Frauen_in_Fuehrungspositionen_26.11.2024.pdf'
        },
        {
            date: '20.11.2024',
            text: 'Zweites Fundraising der KI-basierten Karriere-Plattform bunton startet',
            pdfFile: '/pressRelease/PDFS/PM_bunton_Zweites Fundraising der KI-basierten Karriere-Plattform bunton_startet_20.11.2024.pdf'
        },
        {
            date: '16.10.2024',
            text: 'Was Frauen in ihren Jobs und am Arbeitsplatz erwarten',
            pdfFile: '/pressRelease/PDFS/PM_bunton_Jobanforderungen_von_Frauen_16.10.2024.pdf'
        },
        {
            date: '10.10.2024',
            text: 'Mehrheitlich Frauen investieren in frauenfokussiertes Geschäftsmodell',
            pdfFile: '/pressRelease/PDFS/PM_bunton_Mehrheitlich Frauen investieren in frauenfokussiertes Geschaeftsmodell_10.10.2024.pdf'
        },
        {
            date: '01.10.2024',
            text: 'Warum CEOs in junge Unternehmen investieren',
            pdfFile: '/pressRelease/PDFS/PM_bunton_Warum CEOs in junge von Frauen gegruendete Unternehmen investieren_01.10.2024.pdf'
        },
        {
            date: '26.07.2024',
            text: 'Besetzung von Vorstands-Positionen voreingenommen männlich dominiert/Weibliche Karrieren unterbewertet und Fähigkeiten sowie Führungserfahrungen von Frauen unterschätzt',
            pdfFile: '/pressRelease/PDFS/PM_bunton_Interviews_Frauen_in_Fuehrungspositionen_26.07.2024.pdf'
        },
        {
            date: '01.07.2024',
            text: 'Besetzung von Vorstands-Positionen voreingenommen männlich dominiert/Weibliche Karrieren unterbewertet und Fähigkeiten sowie Führungserfahrungen von Frauen unterschätzt',
            pdfFile: '/pressRelease/PDFS/PM_bunton_beruft_weitere_Beiratsmitglieder_01.07.2024.pdf'
        },
        {
            date: '26.02.2024',
            text: 'bunton schließt erfolgreich erste Finanzierungsrunde ab',
            pdfFile: '/pressRelease/PDFS/PM_bunton_schliesst_erfolgreich_erste_Finanzierungsrunde_ab_26.02.2024.pdf'
        }
    ]

    return(
        <>
            { ['web'].includes(responsiveBreakpoint) &&
                <Grid item xs={8}>
                    <StyledPressReleaseDetails
                        maxWidth="xl"
                        disableGutters
                        sx={{
                            opacity: "1",
                            minHeight: "160px",
                            maxWidth: "100%",
                            borderRadius: {
                            xs: "50px",
                            md: "80px",
                            },
                        }}>
                        <Grid item xs={12} sx={{fontSize: '20px !important', marginBottom: '29px'}}>
                            <strong sx={{color: '#002D59', letterSpacing: '0.2px'}}>Pressmitteilungen</strong>
                        </Grid>
                        {announcements.map((item, index) => (
                            <Grid item xs={12} key={index}>
                                <Grid container spacing={2} sx={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            mt: 0
                                        }}>
                                    <Grid item xs={10} >
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} >
                                                <Box>
                                                    {item.date}
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12} >
                                                <Box sx={{ fontWeight: '600', position: "relative", top: "-19px" }}>
                                                    {item.text}
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Box>
                                            <Button variant="contained"
                                                    size="small"
                                                    href={item.pdfFile}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    sx={{
                                                        width: '98px',
                                                        paddingLeft: '22px',
                                                        float: 'right',
                                                        color: '#FFFAF4',
                                                        background: '#012e5a',
                                                        top: '-9px',
                                                        height: {xs: '39px', sm: '39px', md: '39px', lg: '39px', xl: '53px'},
                                                        [theme.breakpoints.between('custom1279', 'custom1746')]: {
                                                            height: '39px'
                                                        }
                                                    }}>
                                                PDF
                                                <Icons
                                                    src={Arrow}
                                                    sx={{
                                                        marginLeft: '10px',
                                                        height: '20px',
                                                        width: '20px',
                                                        top: '9px'
                                                    }}
                                                />
                                            </Button>
                                        </Box>
                                    </Grid>
                                </Grid>
                                <Divider
                                    sx={{
                                        borderBottomWidth: 2,
                                        borderColor: '#bcddd2'
                                    }}
                                />
                            </Grid>
                        ))}
                    </StyledPressReleaseDetails>
                </Grid>
            }
            { ['tab'].includes(responsiveBreakpoint) &&
                <Grid item xs={12}>
                    <StyledPressReleaseDetails
                        maxWidth="xl"
                        disableGutters
                        sx={{
                            opacity: "1",
                            minHeight: "160px",
                            maxWidth: "100%",
                            borderRadius: {
                            xs: "50px",
                            md: "80px",
                            },
                        }}>
                        <Grid item xs={12} sx={{fontSize: '20px !important', marginBottom: '29px'}}>
                            <strong sx={{color: '#002D59', letterSpacing: '0.2px'}}>Pressmitteilungen</strong>
                        </Grid>
                        {announcements.map((item, index) => (
                            <Grid item xs={12} key={index}>
                                <Grid container spacing={2} sx={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            mt: 0
                                        }}>
                                    <Grid item xs={10} >
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} >
                                                <Box>
                                                    {item.date}
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12} >
                                                <Box sx={{ fontWeight: '600', position: "relative", top: "-19px" }}>
                                                    {item.text}
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Box>
                                            <Button variant="contained"
                                                    size="small"
                                                    href={item.pdfFile}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    sx={{
                                                        width: '98px',
                                                        paddingLeft: '22px',
                                                        float: 'right',
                                                        color: '#FFFAF4',
                                                        background: '#012e5a',
                                                        top: '-9px',
                                                        height: {xs: '39px', sm: '39px', md: '39px', lg: '39px', xl: '53px'},
                                                        [theme.breakpoints.between('custom1279', 'custom1746')]: {
                                                            height: '39px'
                                                        }
                                                    }}>
                                                PDF
                                                <Icons
                                                    src={Arrow}
                                                    sx={{
                                                        marginLeft: '10px',
                                                        height: '20px',
                                                        width: '20px',
                                                        top: '9px'
                                                    }}
                                                />
                                            </Button>
                                        </Box>
                                    </Grid>
                                </Grid>
                                <Divider
                                    sx={{
                                        borderBottomWidth: 2,
                                        borderColor: '#bcddd2'
                                    }}
                                />
                            </Grid>
                        ))}
                    </StyledPressReleaseDetails>
                </Grid>
            }
            { ['mob'].includes(responsiveBreakpoint) &&
                <Grid item xs={12}>
                    <StyledPressReleaseDetails
                        maxWidth="xl"
                        disableGutters
                        sx={{
                            opacity: "1",
                            minHeight: "160px",
                            maxWidth: "100%",
                            borderRadius: {
                                xs: "50px",
                                md: "80px",
                            },
                        }}>
                        <Grid item xs={12} sx={{fontSize: '18px !important', marginBottom: '12px'}}>
                            <strong sx={{color: '#002D59', letterSpacing: '0.2px'}}>Pressmitteilungen</strong>
                        </Grid>
                        {announcements.map((item, index) => (
                            <Grid item xs={12} key={index}>
                                <Grid container spacing={2} sx={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            mt: 0
                                        }}>
                                    <Grid item xs={12} >
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} >
                                                <Box
                                                    component="a"
                                                    href={item.pdfFile}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    sx={{fontSize: '15px'}}
                                                >
                                                    {item.date}
                                                </Box>
                                            </Grid>
                                            <Grid item xs={12} >
                                                <Box
                                                    component="a"
                                                    href={item.pdfFile}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    sx={{
                                                        fontWeight: '600',
                                                        position: "relative",
                                                        top: "-19px",
                                                        fontSize: '15px'
                                                    }}
                                                >
                                                    {item.text}
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Divider
                                    sx={{
                                        borderBottomWidth: 2,
                                        borderColor: '#bcddd2'
                                    }}
                                />
                            </Grid>
                        ))}
                    </StyledPressReleaseDetails>
                </Grid>
            }
        </>
    )
}

export default Announcements;