import * as React from 'react';
import { Typography, Box, Grid } from '@mui/material';
import PressCoverageCard from './PressCoverageCard';
import { StyledPressReleaseDetails } from './pressStyles'
import DEUTSCHESTARTUPS from '../../assets/images/pressRelease/logosARTICLES/deutschestartups.jpg'
import FAZ from '../../assets/images/pressRelease/logosARTICLES/faz.jpg'
import INGENIEUR from '../../assets/images/pressRelease/logosARTICLES/ingenieur.de.jpg'
import KARRIEREFUERER from '../../assets/images/pressRelease/logosARTICLES/karrierefuerer.jpg'
import PERSONALWIRTSCHAFT from '../../assets/images/pressRelease/logosARTICLES/personalwirtschaft.jpg'
import SCHECONOMY from '../../assets/images/pressRelease/logosARTICLES/sheconomy.jpg'
import STARTUPVALLEY from '../../assets/images/pressRelease/logosARTICLES/startupvalley.jpg'
import STATION from '../../assets/images/pressRelease/logosARTICLES/station.jpg'
import { useSelector } from 'react-redux'

const BuntonArticles = () => {
    const { responsiveBreakpoint } = useSelector(state => state.initial)
    const pressCoverageData = [
        {
            date: '20.11.2024',
            company_name: 'Frankfurter Allgemeine Zeitung',
            title: 'Was man Frauen bieten muss',
            link: 'https://www.faz.net/aktuell/rhein-main/wirtschaft/fuehrungskraefte-was-frauen-sich-wuenschen-110123821.html',
            buttonText: 'Artikel lesen',
            image: FAZ
        },
        {
            date: '14.08.2024',
            company_name: 'sheconomy - Die neuen Seiten der Wirtschaft',
            title: 'Vorurteile in der Vorstandsetage: Weibliche Führungskräfte werden unterschätzt',
            link: 'https://sheconomy.media/vorurteile-in-der-vorstandsetage-weibliche-fuehrungskraefte-werden-unterschaetzt',
            buttonText: 'Artikel lesen',
            image: SCHECONOMY
        },
        {
            date: '28.07.2024',
            company_name: 'Frankfurter Allgemeine Zeitung',
            title: '„Der Headhunter hielt mich für die Sekretärin.“',
            link: 'https://www.faz.net/aktuell/rhein-main/wirtschaft/frauen-in-fuehrungsposten-der-headhunter-hielt-mich-fuer-die-sekretaerin-19879376.html',
            buttonText: 'Artikel lesen',
            image: FAZ
        },
        {
            date: '24.04.2024',
            company_name: 'karriereführer - Das Jobmagazin für Hochschulabsolvent*innen',
            title: 'Frauen in Führungspositionen 2024.2025 – Weg mit den Bremsklötzen',
            link: 'https://www.karrierefuehrer.de/e-paper/frauen-in-fuehrungspositionen-2024-2025-weg-mit-den-bremskloetzen.html',
            buttonText: 'E-Paper lesen',
            image: KARRIEREFUERER
        },
        {
            date: '25.03.2024',
            company_name: 'Ingenieur.de - Technik - Karriere - News',
            title: 'KI-basiertes Job-Matching: Wie weibliche Führungskräfte an die Jobs kommen',
            link: 'https://www.ingenieur.de/karriere/bewerbung/ki-basiertes-job-matching-wie-weibliche-fuehrungskraefte-an-die-jobs-kommen/',
            buttonText: 'Artikel lesen',
            image: INGENIEUR
        },
        {
            date: '26.02.2024',
            company_name: 'Personalwirtschaft',
            title: 'HR-Start-up Bunton sammelt erstmals Geld ein',
            link: 'https://www.personalwirtschaft.de/news/recruiting/hr-start-up-bunton-sammelt-erstmals-geld-ein-170962/',
            buttonText: 'Artikel lesen',
            image: PERSONALWIRTSCHAFT
        },
        {
            date: '22.02.2024',
            company_name: 'STATION. Frankfurt am Main',
            title: 'KI Startup bunton schließt erfolgreich erste Finanzierungsrunde ab',
            link: 'https://station-frankfurt.de/2024/02/22/ki-startup-bunton-schliesst-erfolgreich-erste-finanzierungsrunde-ab/',
            buttonText: 'Artikel lesen',
            image: STATION
        },
        {
            date: '16.11.2023',
            company_name: 'deutsche startups',
            title: 'Community für weibliche Führungskräfte und Aufsichtsräte',
            link: 'https://www.deutsche-startups.de/2023/11/16/neue-startups-holi-wasteant/',
            buttonText: 'Artikel lesen',
            image: DEUTSCHESTARTUPS
        },
        {
            date: '26.09.2023',
            company_name: 'StartupValley',
            title: 'Baut euch sehr früh ein eigenes starkes Businessnetzwerk auf',
            link: 'https://startupvalley.news/de/bunton/',
            buttonText: 'Artikel lesen',
            image: STARTUPVALLEY
        },
    ]

  return (
    <>
        { ['web'].includes(responsiveBreakpoint) &&
            <Grid item xs={8}>
                <StyledPressReleaseDetails
                    maxWidth="xl"
                    disableGutters
                    sx={{
                        opacity: "1",
                        minHeight: "160px",
                        maxWidth: "100%",
                        borderRadius: {
                        xs: "50px",
                        md: "80px",
                        },
                    }}
                >
                    <Grid item xs={12}>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Typography
                            variant="h3"
                            className="strong small-size-18px"
                            sx={{ wordBreak: "break-all", marginBottom: '32px', fontSize: '20px !important' }}
                        >
                            <strong sx={{color: '#002D59', letterSpacing: '0.2px'}}>bunton in der Presse</strong>
                        </Typography>
                        </Box>
                    </Grid>

                    {pressCoverageData.map((item, index) => (
                        <PressCoverageCard image={item.image} date={item.date} companyName={item.company_name} title={item.title} link={item.link} buttonText={item.buttonText} index={index}/>
                    ))}
                </StyledPressReleaseDetails>
            </Grid>
        }
        { ['tab'].includes(responsiveBreakpoint) &&
            <Grid item xs={12} sx={{marginTop: '54px'}}>
                <StyledPressReleaseDetails
                    maxWidth="xl"
                    disableGutters
                    sx={{
                        opacity: "1",
                        minHeight: "160px",
                        maxWidth: "100%",
                        borderRadius: {
                        xs: "50px",
                        md: "80px",
                        },
                    }}
                >
                    <Grid item xs={12}>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Typography
                            variant="h3"
                            className="strong small-size-18px"
                            sx={{ wordBreak: "break-all", marginBottom: '32px', fontSize: '20px !important' }}
                        >
                            <strong sx={{color: '#002D59', letterSpacing: '0.2px'}}>bunton in der Presse</strong>
                        </Typography>
                        </Box>
                    </Grid>

                    {pressCoverageData.map((item, index) => (
                        <PressCoverageCard image={item.image} date={item.date} companyName={item.company_name} title={item.title} link={item.link} buttonText={item.buttonText} index={index}/>
                    ))}
                </StyledPressReleaseDetails>
            </Grid>
        }
        { ['mob'].includes(responsiveBreakpoint) &&
            <Grid item xs={12}>
                <StyledPressReleaseDetails
                    maxWidth="xl"
                    disableGutters
                    sx={{
                        opacity: "1",
                        minHeight: "160px",
                        maxWidth: "100%",
                        borderRadius: {
                        xs: "50px",
                        md: "80px",
                        },
                    }}
                >
                    <Grid item xs={12}>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Typography
                            variant="h3"
                            className="strong small-size-18px"
                            sx={{ wordBreak: "break-all", marginBottom: '10px', fontSize: '18px !important' }}
                        >
                            <strong sx={{color: '#002D59', letterSpacing: '0.2px'}}>bunton in der Presse</strong>
                        </Typography>
                        </Box>
                    </Grid>

                    {pressCoverageData.map((item, index) => (
                        <PressCoverageCard image={item.image} date={item.date} companyName={item.company_name} title={item.title} link={item.link} buttonText={item.buttonText} index={index}/>
                    ))}
                </StyledPressReleaseDetails>
            </Grid>
        }
    </>
  );
};

export default BuntonArticles;
