import * as React from 'react';
import { Box, Grid, Typography, Button} from '@mui/material'
import { StyledPressReleaseDetails } from './pressStyles'
import BuntonLogo from '../../assets/images/pressRelease/BUNTON_LOGO_DOWNLOAD.png'
import DownArrow from './Icons/DownArrow.svg'
import Icons from '../../components/Icons'
import { createTheme } from '@mui/material/styles';
import { useSelector } from 'react-redux'

const theme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1200,
        custom1279: 1279,
        custom1536: 1536,
        custom1681: 1681,
        custom1746: 1746,
        xl: 1920,
      },
    },
});

const DownloadLogo = () => {
    const handleDownload = (src, name) => {
        const link = document.createElement('a');
        link.href = src;
        link.download = name;
        link.click();
    };
    const { responsiveBreakpoint } = useSelector(state => state.initial)

    return(
        <>
            { ['web'].includes(responsiveBreakpoint) &&
                <Grid item xs={8}>
                    <StyledPressReleaseDetails
                        maxWidth="xl"
                        disableGutters
                        sx={{
                            opacity: "1",
                            minHeight: "160px",
                            maxWidth: "100%",
                            borderRadius: {
                            xs: "50px",
                            md: "80px",
                            },
                        }}
                    >   <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                                <Typography
                                    variant="h3"
                                    className="strong small-size-18px"
                                    sx={{ wordBreak: "break-all", marginBottom: '32px', fontSize: '20px !important' }}
                                >
                                    <strong sx={{color: '#002D59', letterSpacing: '0.2px'}}>Logo herunterladen</strong>
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Grid container spacing={2} sx={{ position: 'relative', top: '16px'}}>
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <Grid container spacing={2} >
                                        <Grid item xs={12} sm={12} md={12} lg={3} xl={3} sx={{display: 'flex'}}>
                                            <Box sx={{ mx: "14px", flexShrink: 0 }}>
                                                <Box
                                                    component="img"
                                                    src={BuntonLogo}
                                                    alt="logo.alt"
                                                    sx={{
                                                        width: { xs: '168px', xl: '225px' },
                                                        height: { xs: '48px', xl: '58px' },
                                                        borderRadius: '30px',
                                                        backgroundColor: '#fffaf4',
                                                        padding: '18px',
                                                        position: 'relative',
                                                        top: '-15px',
                                                        marginRight: '1rem'
                                                    }}
                                                />
                                                <Box sx={{
                                                        position: 'relative',
                                                        top: {xs: '-1px', sm: '-1px', md: '-1px', lg: '-1px', xl: '5px'},
                                                        left: {xs: '-6px', sm: '-6px', md: '-6px', lg: '-6px', xl: '0'},
                                                        float: 'right',
                                                        [theme.breakpoints.between('custom1279', 'custom1681')]: {
                                                            top: '-1px'
                                                        }
                                                    }}>
                                                    <Box sx={{ fontWeight: '600' }}>
                                                        bunton Logo
                                                    </Box>
                                                    <Box sx={{ position: 'relative', top: '-3px', left: '1px' }}>
                                                        PNG 2000x564 px
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12} lg={9} xl={9}>
                                            <Box sx={{float: 'right'}}>
                                                <Button variant="contained" size="small" href="#" sx={{
                                                    width: { xs: '181px', sm: '181px', md: '224px', lg: '224px', xl: '220px'},
                                                    float: 'right',
                                                    height: {xs: '39px', sm: '39px', md: '39px', lg: '39px', xl: '53px'},
                                                    background: '#002d59',
                                                    color: '#FFFAF4',
                                                    [theme.breakpoints.between('lg', 'custom1746')]: {
                                                        width: '224px',
                                                        height: '39px'
                                                    }
                                                }}
                                                onClick={() => handleDownload(BuntonLogo, 'Bunton Logo')}
                                                >
                                                    PNG herunterladen
                                                    <Icons
                                                        src={DownArrow}
                                                        sx={{
                                                            marginLeft: '10px',
                                                            height: '20px',
                                                            width: '20px',
                                                            top: '9px'
                                                        }}
                                                    />
                                                </Button>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </StyledPressReleaseDetails>
                </Grid>
            }
            { ['tab'].includes(responsiveBreakpoint) &&
                <Grid item xs={12}>
                    <StyledPressReleaseDetails
                        maxWidth="xl"
                        disableGutters
                        sx={{
                            opacity: "1",
                            minHeight: "160px",
                            maxWidth: "100%",
                            borderRadius: {
                            xs: "50px",
                            md: "80px",
                            },
                        }}
                    >   <Grid item xs={12}>
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                                <Typography
                                    variant="h3"
                                    className="strong small-size-18px"
                                    sx={{ wordBreak: "break-all", marginBottom: '32px', fontSize: '20px !important' }}
                                >
                                    <strong sx={{color: '#002D59', letterSpacing: '0.2px'}}>Logo herunterladen</strong>
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={2} sx={{ position: 'relative', top: '16px'}}>
                                <Grid item xs={12}>
                                    <Grid container spacing={2} >
                                        <Grid item xs={8} sx={{display: 'flex'}}>
                                            <Box sx={{ mx: "14px", flexShrink: 0 }}>
                                                <Box
                                                    component="img"
                                                    src={BuntonLogo}
                                                    alt="logo.alt"
                                                    sx={{
                                                        width: { xs: '168px', xl: '225px' },
                                                        height: { xs: '48px', xl: '58px' },
                                                        borderRadius: '30px',
                                                        backgroundColor: '#fffaf4',
                                                        padding: '18px',
                                                        position: 'relative',
                                                        top: '-15px',
                                                        marginRight: '1rem',
                                                        marginLeft: '-14px'
                                                    }}
                                                />
                                                <Box sx={{
                                                        position: 'relative',
                                                        top: '-1px',
                                                        left: '-6px',
                                                        float: 'right',
                                                    }}>
                                                    <Box sx={{ fontWeight: '600' }}>
                                                        bunton Logo
                                                    </Box>
                                                    <Box sx={{ position: 'relative', top: '-3px', left: '1px' }}>
                                                        PNG 2000x564 px
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Box sx={{float: 'right'}}>
                                                <Button variant="contained" size="small" href="#" sx={{
                                                    width: '181px',
                                                    float: 'right',
                                                    height: '39px',
                                                    background: '#002d59',
                                                    color: '#FFFAF4',
                                                }}
                                                onClick={() => handleDownload(BuntonLogo, 'Bunton Logo')}
                                                >
                                                    herunterladen
                                                    <Icons
                                                        src={DownArrow}
                                                        sx={{
                                                            marginLeft: '10px',
                                                            height: '20px',
                                                            width: '20px',
                                                            top: '9px'
                                                        }}
                                                    />
                                                </Button>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </StyledPressReleaseDetails>
                </Grid>
            }
            { ['mob'].includes(responsiveBreakpoint) &&
                <Grid item xs={12}>
                    <StyledPressReleaseDetails
                        maxWidth="xl"
                        disableGutters
                        sx={{
                            opacity: "1",
                            minHeight: "160px",
                            maxWidth: "100%",
                            borderRadius: {
                                xs: "50px",
                                md: "80px",
                            },
                        }}
                    >   <Grid item xs={12}>
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                                <Typography
                                    variant="h3"
                                    className="strong small-size-18px"
                                    sx={{ wordBreak: "break-all", marginBottom: '32px', fontSize: '18px !important' }}
                                >
                                    <strong sx={{color: '#002D59', letterSpacing: '0.2px'}}>Logo herunterladen</strong>
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={2} >
                                <Grid item xs={6} sx={{display: 'flex'}}>
                                    <Box
                                        component="img"
                                        src={BuntonLogo}
                                        alt="Bunton Logo"
                                        onClick={() => handleDownload(BuntonLogo, 'Bunton Logo')}
                                        sx={{
                                            width: { xs: '116px' },
                                            height: { xs: '41px' },
                                            borderRadius: '30px',
                                            backgroundColor: '#fffaf4',
                                            padding: '18px',
                                            position: 'relative',
                                            top: '-15px',
                                            marginRight: '1rem'
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={6} sx={{display: 'flex'}}>
                                    <Box
                                        component="a"
                                        href="#"
                                        onClick={(e) => {
                                          e.preventDefault();
                                          handleDownload(BuntonLogo, 'Bunton Logo');
                                        }}
                                        sx={{
                                            position: 'relative',
                                            top: {xs: '5px' },
                                            left: {xs: '23px' },
                                            float: 'right',
                                            fontSize: '14px'
                                        }}>
                                        <Box sx={{ fontWeight: '600' }}>
                                            bunton Logo
                                        </Box>
                                        <Box sx={{ position: 'relative', top: '-3px', left: '1px' }}>
                                            PNG 2000x564 px
                                        </Box>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>
                    </StyledPressReleaseDetails>
                </Grid>
            }
        </>
    )
}

export default DownloadLogo;