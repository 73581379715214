import * as React from 'react';
import { Box, Grid, Typography, Button} from '@mui/material'
import Icons from '../../components/Icons'
import Arrow from './Icons/RightArrow.svg'
import Divider from '@mui/material/Divider'
import { createTheme } from '@mui/material/styles';
import { useSelector } from 'react-redux'

const theme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1200,
        custom1279: 1279,
        custom1536: 1536,
        custom1681: 1681,
        custom1706: 1706,
        custom1746: 1746,
        xl: 1920,
      },
    },
});

const PressCoverageCard = ({ image, date, companyName, title, link, buttonText, index }) => {
    const { responsiveBreakpoint } = useSelector(state => state.initial)
    return (
        <>
            { ['web'].includes(responsiveBreakpoint) &&
                <Grid container spacing={2} key={index} sx={{ position: 'relative', top: '12px'}}>
                    <Grid item xs={1}>
                        <Box
                            component="img"
                            src={image}
                            alt={`image-${index}`}
                            sx={{
                                width: "60px",
                                height: "60px",
                                backgroundColor: '#fff',
                                objectFit: 'contain',
                                borderRadius: '50%'
                            }}
                        />
                    </Grid>
                    <Grid item xs={11}>
                        <Grid container spacing={2}>
                            <Grid item xs={9} sm={9} md={9} lg={9} xl={8}>
                                <Grid container sx={{
                                        position: 'relative', top: '7px', left: { lg: '1rem', xl: '0' },
                                        [theme.breakpoints.between('custom1279', 'custom1746')]: {
                                            left: '1rem'
                                        }
                                    }}>
                                    <Grid item xs={12}>
                                        <Box sx={{
                                                    display: 'flex',
                                                    flexDirection: { xs: 'column',   sm: 'column',   md: 'column',   lg: 'column',   xl: 'row'  },
                                                    alignItems: {  xs: 'flex-start',  sm: 'flex-start',  md: 'flex-start',  lg: 'flex-start', xl: 'center'  },
                                                    gap: '4px',
                                                    [theme.breakpoints.between('custom1279', 'custom1746')]: {
                                                        flexDirection: 'column',
                                                        alignItems: 'flex-start'
                                                    }
                                                }}>
                                            <Typography variant="body2" color="text.secondary" className='item-date' sx={{ color: '#0b3660' }}>
                                                {date}
                                            </Typography>
                                            <Typography variant="body1" sx={{
                                                                            mx: 1,
                                                                            display: {xs: 'none', sm: 'none', md: 'none', lg: 'none', xl: 'block'},
                                                                            [theme.breakpoints.between('custom1279', 'custom1746')]: {
                                                                                display: 'none'
                                                                            }
                                                                        }}>•</Typography>
                                            <Typography variant="subtitle1" component="div" className='item-company' sx={{ position: 'relative' }}>
                                                {companyName}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Box>
                                            <Typography variant="subtitle1" component="div" className='item-title' sx={{ position: 'relative', fontWeight: '600', color: '#002d59' }}>
                                                {title}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={3} sm={3} md={3} lg={3} xl={4}>
                                <Box>
                                    <Button variant="contained" size="small" href={link} target="_blank" sx={{
                                                                                                            width: {xs: '175px', sm: '175px', md: '175px', lg: '175px', xl: '203px'},
                                                                                                            height: {xs: '39px', sm: '39px', md: '39px', lg: '39px', xl: '53px'},
                                                                                                            letterSpacing: '0.32px',
                                                                                                            textTransform: 'none',
                                                                                                            letterSpacing: '0px',
                                                                                                            top: { xs: '-5px', sm: '-5px', md: '-5px', lg: '-5px', xl: '5px'},
                                                                                                            float: 'right',
                                                                                                            [theme.breakpoints.between('custom1279', 'custom1746')]: {
                                                                                                                width: '175px',
                                                                                                                height: '39px',
                                                                                                                top: '-5px'
                                                                                                            }
                                                                                                        }}>
                                        {buttonText}
                                        <Icons
                                            src={Arrow}
                                            sx={{
                                                marginLeft: '10px',
                                                height: '20px',
                                                width: '20px',
                                                top: '9px',
                                                color: '#FFFAF4'
                                            }}
                                        />
                                    </Button>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            }
            { ['tab'].includes(responsiveBreakpoint) &&
                <Grid container spacing={2} key={index} sx={{ position: 'relative', top: '12px'}}>
                    <Grid item xs={1}>
                        <Box
                            component="img"
                            src={image}
                            alt={`image-${index}`}
                            sx={{
                                width: "60px",
                                height: "60px",
                                backgroundColor: '#fff',
                                objectFit: 'contain',
                                borderRadius: '50%'
                            }}
                        />
                    </Grid>
                    <Grid item xs={11}>
                        <Grid container spacing={2}>
                            <Grid item xs={9} sm={9} md={9} lg={9} xl={8}>
                                <Grid container sx={{
                                        position: 'relative', top: '7px', left: '21px'
                                    }}>
                                    <Grid item xs={12}>
                                        <Box sx={{
                                                    display: 'flex',
                                                    flexDirection: { xs: 'column',   sm: 'column',   md: 'column',   lg: 'column',   xl: 'row'  },
                                                    alignItems: {  xs: 'flex-start',  sm: 'flex-start',  md: 'flex-start',  lg: 'flex-start', xl: 'center'  },
                                                    gap: '4px',
                                                }}>
                                            <Typography variant="body2" color="text.secondary" className='item-date' sx={{ color: '#0b3660' }}>
                                                {date}
                                            </Typography>
                                            <Typography variant="subtitle1" component="div" className='item-company' sx={{ position: 'relative' }}>
                                                {companyName}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Box>
                                            <Typography variant="subtitle1" component="div" className='item-title' sx={{ position: 'relative', fontWeight: '600', color: '#002d59' }}>
                                                {title}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={3} sm={3} md={3} lg={3} xl={4}>
                                <Box>
                                    <Button variant="contained" size="small" href={link} target="_blank" sx={{
                                                                                                            width: '175px',
                                                                                                            height: '39px',
                                                                                                            letterSpacing: '0.32px',
                                                                                                            textTransform: 'none',
                                                                                                            letterSpacing: '0px',
                                                                                                            top: { xs: '-5px', sm: '-5px', md: '-5px', lg: '-5px', xl: '5px'},
                                                                                                            float: 'right',
                                                                                                        }}>
                                        {buttonText}
                                        <Icons
                                            src={Arrow}
                                            sx={{
                                                marginLeft: '10px',
                                                height: '20px',
                                                width: '20px',
                                                top: '9px',
                                                color: '#FFFAF4'
                                            }}
                                        />
                                    </Button>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            }
            { ['mob'].includes(responsiveBreakpoint) &&
                <Grid container spacing={2} key={index} sx={{ position: 'relative', top: '12px', alignItems: 'center' }}>
                    <Grid item xs={2} sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', minHeight: '80px' }}>
                        <Box
                            component="img"
                            src={image}
                            alt={`image-${index}`}
                            sx={{
                                width: "50px",
                                height: "50px",
                                backgroundColor: '#fff',
                                objectFit: 'contain',
                                borderRadius: '50%',
                            }}
                        />
                    </Grid>
                    <Grid item xs={10} sx={{ display: 'flex', justifyContent: 'flex-end', flexDirection: 'column' }}>
                        <Grid container spacing={1} sx={{marginLeft: 0}}>
                            <Grid item xs={12}>
                                <Typography
                                    component="a"
                                    href={link}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    variant="body2"
                                    sx={{ color: '#0b3660', fontSize: '14px', pl: 1 }}>
                                    {date}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}  sx={{float: 'right'}}>
                                <Typography
                                    component="a"
                                    href={link}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    variant="body2"
                                    sx={{
                                        display: 'inline-block',
                                        fontSize: '14px',
                                        pl: 1,
                                        wordBreak: 'break-word',
                                        whiteSpace: 'normal',
                                        marginTop: '-3px'
                                    }}
                                >
                                    {companyName}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}  sx={{float: 'right'}}>
                                <Typography
                                    component="a"
                                    href={link}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    variant="subtitle1"
                                    sx={{
                                        display: 'inline-block',
                                        fontWeight: '600',
                                        color: '#002d59',
                                        fontSize: '14px',
                                        pl: 1,
                                        wordBreak: 'break-word',
                                        whiteSpace: 'normal',
                                        mt: '-3px',
                                        textDecoration: 'none',
                                        '&:hover': { textDecoration: 'none' }
                                    }}
                                    >
                                    {title}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
              </Grid>
            }
            { ['web'].includes(responsiveBreakpoint) &&
                <Divider
                    sx={{
                        mt: {xs: '33px', sm: '33px', md: '33px', lg: '33px', xl: '16px'},
                        borderBottomWidth: 2,
                        borderColor: '#bcddd2',
                        marginBottom: {xs: '13px', sm: '13px', md: '13px', lg: '13px', xl: '0px'},
                        paddingTop: {xs: '17px', sm: '17px', md: '17px', lg: '17px', xl: '0px'},
                        [theme.breakpoints.between('custom1279', 'custom1746')]: {
                            mt: '33px',
                            marginBottom: '13px',
                            paddingTop: '17px'
                        }
                    }}
                />
            }
            { ['tab'].includes(responsiveBreakpoint) &&
                <Divider
                    sx={{
                        mt: {xs: '33px', sm: '33px', md: '33px', lg: '33px', xl: '16px'},
                        borderBottomWidth: 2,
                        borderColor: '#bcddd2',
                        marginBottom: {xs: '13px', sm: '13px', md: '13px', lg: '13px', xl: '0px'},
                        paddingTop: {xs: '17px', sm: '17px', md: '17px', lg: '17px', xl: '0px'},
                    }}
                />
            }
            { ['mob'].includes(responsiveBreakpoint) &&
                <Divider
                    sx={{
                        borderBottomWidth: 2,
                        borderColor: '#bcddd2',
                        paddingTop: {xs: '17px'},
                        paddingBottom: {xs: '4px'},
                    }}
                />
            }
        </>
    );
};

export default PressCoverageCard;
