import React, { useEffect } from 'react'
import Layout from '../template/Layout'
import { Box, Container, Typography } from '@mui/material'
import NotificationSettings from '../components/organisams/NotificationSettings'
import { useParams } from 'react-router-dom'
import { useMutation } from '@apollo/client'
import { UNSUBSCRIBE_EMAIL_MUTATION } from '../queries/settingsQueries'
import SuccessIcon from '../assets/icons/success_green.svg'
import { useSelector } from 'react-redux'
import PageMetaTags from '../base/PageMetaTags'

const UnsubscribeEmail = props => {
    const params = useParams()
    const { isUserAuthenticated } =
        useSelector(state => state.login)
    const [unsubscribeEmailMutation, { loading, data, error }] = useMutation(UNSUBSCRIBE_EMAIL_MUTATION, {
        onCompleted: () => {}
    })
    useEffect(() => {
        unsubscribeEmailMutation({
            variables: {
                data: params.key
            }
        })
    }, [])
    return (
        <Layout>
            <PageMetaTags title='Notification Preferencer | Bunton'>
                <meta name="robots" content="noindex" />
            </PageMetaTags>
            <Container maxWidth='xl'>
                {data && (
                    <Box>
                        <Box
                            sx={{
                                
                                textAlign:'center',
                                maxWidth: '500px',
                                m: 'auto',
                                my: 4,
                            }}
                        >
                            <Box
                                component='img'
                                src={SuccessIcon}
                                height='50px'
                                width='50px'
                                sx={{ mb: 2 }}
                            />
                            <Typography
                                variant='h3'
                                className='extrabold'
                                sx={{ textAlign: 'center' }}
                            >
                                You have successfully unsubscribed from our {`"${data.unsubscribeEmailNotification.name}"`} mailing list. 
                            </Typography>
                        </Box>
                        {isUserAuthenticated && <Box sx={{maxWidth: '700px', m: 'auto'}}>
                            <NotificationSettings />
                        </Box>}
                    </Box>
                )}
            </Container>
        </Layout>
    )
}

export default UnsubscribeEmail
