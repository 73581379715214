import * as React from 'react';
import { Typography, List, ListItem, Grid, Box } from '@mui/material';
import Divider from '@mui/material/Divider'
import { createTheme } from '@mui/material/styles';
import { useSelector } from 'react-redux'

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      custom1280: 1280,
      custom1536: 1536,
      custom1680: 1680,
      custom1706: 1706,
      custom1745: 1745,
      xl: 1920,
    },
  },
});

const KeyFactsSection = () => {
  const { responsiveBreakpoint } = useSelector(state => state.initial)
  return (
    <>
      { ['web'].includes(responsiveBreakpoint) &&
        <Grid container spacing={2} sx={{ padding: 2 }}>
          <Grid item xs={12}>
            <Box>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography
                    variant="h3"
                    gutterBottom
                    sx={{
                      fontWeight: '900',
                      letterSpacing: '0.2px',
                      fontSize: { xs: '20px', sm: '20px', md: '20px', lg: '20px', xl: '20px' },
                    }}
                  >
                    Fakten
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Divider
                    sx={{
                      borderBottomWidth: 2,
                      borderColor: '#bcddd2',
                      marginRight: { xs: '-71px', xl: '0px' },
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{height: '3rem'}}>
                  <List>
                    <ListItem>
                      <Box sx={{ display: 'flex', width: '100%', flexWrap: 'wrap', justifyContent: 'space-between'}}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={3}>
                          <Typography sx={{position: 'relative', left: '-1rem'}}>Gegründet</Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={9}>
                          <Typography
                            sx={{
                              fontWeight: 900,
                              marginTop: { xs: '4px', sm: '4px', md: '4px', lg: '4px', xl: '0px' },
                              position: 'relative',
                              right: '-1rem',
                              float: 'right',
                              left: {lg: '-103px', xl: '0'},
                              [theme.breakpoints.only('custom1280')]: {
                                left: '-8.1rem',
                                marginTop: '0.5rem'
                              },
                              [theme.breakpoints.only('custom1536')]: {
                                left: '-13.4rem',
                                marginTop: '1.5rem'
                              },
                              [theme.breakpoints.only('custom1680')]: {
                                left: '-16.5rem',
                                marginTop: '1.5rem'
                              },
                              [theme.breakpoints.only('custom1706')]: {
                                left: '-16.9rem',
                                marginTop: '1.5rem'
                              },
                              [theme.breakpoints.only('custom1745')]: {
                                left: '-17.8rem',
                                marginTop: '1.5rem'
                              }
                            }}
                          >
                            März 2022
                          </Typography>
                        </Grid>
                      </Box>
                    </ListItem>
                  </List>
                </Grid>
                <Grid item xs={12} sm={12} md={12} sx={{height: '3rem', marginBottom: {lg: '18px', xl: '0'}}}>
                  <List>
                    <ListItem sx={{
                                marginTop: { xs: '21px', sm: '21px', md: '21px', lg: '21px', xl: '0px' },
                                [theme.breakpoints.only('custom1536')]: {
                                  top: '9px'
                                },
                                [theme.breakpoints.only('custom1680')]: {
                                  top: '9px'
                                },
                                [theme.breakpoints.only('custom1706')]: {
                                  top: '9px'
                                },
                                [theme.breakpoints.only('custom1745')]: {
                                  top: '9px'
                                }
                              }}>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          width: '100%',
                          flexWrap: 'wrap',
                        }}
                      >
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={3}>
                          <Typography
                            sx={{
                              flex: '1',
                              minWidth: '120px',
                              position: 'relative',
                              left: '-1rem'
                            }}
                          >
                            Founders
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={9}>
                          <Typography
                            sx={{
                              fontWeight: 900,
                              marginTop: { xs: '4px', sm: '4px', md: '4px', lg: '4px', xl: '0px' },
                              whiteSpace: { xs: 'nowrap', xl: 'normal' },
                              flex: '2',
                              wordBreak: 'break-word',
                              position: 'relative',
                              right: '-1rem',
                              float: 'right',
                              left: {lg: '82px', xl: '0'},
                              [theme.breakpoints.only('custom1280')]: {
                                left: '3.6rem',
                                whiteSpace: 'nowrap'
                              },
                              [theme.breakpoints.only('custom1536')]: {
                                left: '-1.8rem',
                                marginTop: '1.4rem',
                                whiteSpace: 'nowrap'
                              },
                              [theme.breakpoints.only('custom1680')]: {
                                left: '-4.9rem',
                                marginTop: '1.4rem',
                                whiteSpace: 'nowrap'
                              },
                              [theme.breakpoints.only('custom1706')]: {
                                left: '-5.3rem',
                                marginTop: '1.4rem',
                                whiteSpace: 'nowrap'
                              },
                              [theme.breakpoints.only('custom1745')]: {
                                left: '-6.2rem',
                                marginTop: '1.5rem',
                                whiteSpace: 'nowrap'
                              }
                            }}
                          >
                            Janet Winkler & Debjit Chaudhuri
                          </Typography>
                        </Grid>
                      </Box>
                    </ListItem>
                  </List>
                </Grid>
                <Grid item xs={12} sm={12} md={12} sx={{height: '3rem'}}>
                  <List>
                    <ListItem sx={{
                                marginTop: { xs: '21px', sm: '21px', md: '21px', lg: '21px', xl: '0px' },
                                [theme.breakpoints.only('custom1280')]: {
                                  top: '-2px'
                                },
                                [theme.breakpoints.only('custom1536')]: {
                                  top: '16px'
                                },
                                [theme.breakpoints.only('custom1680')]: {
                                  top: '16px'
                                },
                                [theme.breakpoints.only('custom1706')]: {
                                  top: '16px'
                                },
                                [theme.breakpoints.only('custom1745')]: {
                                  top: '16px'
                                }
                              }}>
                      <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={3}>
                          <Typography sx={{position: 'relative', left: '-1rem'}}>Standort</Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={9}>
                          <Typography
                            sx={{
                              fontWeight: 900,
                              marginTop: { xs: '4px', sm: '4px', md: '4px', lg: '26px', xl: '0px' },
                              whiteSpace: { xs: 'nowrap', xl: 'normal' },
                              position: 'relative',
                              right: '-1rem',
                              float: 'right',
                              left: {lg: '-85px', xl: '0'},
                              [theme.breakpoints.only('custom1280')]: {
                                left: '-5.4rem',
                                marginTop: '1.7rem',
                                whiteSpace: 'nowrap'
                              },
                              [theme.breakpoints.only('custom1536')]: {
                                left: '-9.3rem',
                                marginTop: '1.4rem',
                                whiteSpace: 'nowrap'
                              },
                              [theme.breakpoints.only('custom1680')]: {
                                left: '-12.3rem',
                                marginTop: '1.4rem',
                                whiteSpace: 'nowrap'
                              },
                              [theme.breakpoints.only('custom1706')]: {
                                left: '-12.8rem',
                                marginTop: '1.4rem',
                                whiteSpace: 'nowrap'
                              },
                              [theme.breakpoints.only('custom1745')]: {
                                left: '-13.6rem',
                                marginTop: '1.4rem',
                                whiteSpace: 'nowrap'
                              }
                            }}
                          >
                            Frankfurt am Main
                          </Typography>
                        </Grid>
                      </Box>
                    </ListItem>
                  </List>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      }
      { ['tab'].includes(responsiveBreakpoint) &&
        <Box>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography
                variant="h3"
                gutterBottom
                sx={{
                  fontWeight: '900',
                  letterSpacing: '0.2px',
                  fontSize: { xs: '20px', sm: '20px', md: '20px', lg: '20px', xl: '20px' },
                }}
              >
                Fakten
              </Typography>
            </Grid>
            <Grid item xs={12} sx={{marginTop: '-7px'}}>
              <Divider sx={{ borderBottomWidth: 2, borderColor: '#bcddd2' }} />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} sx={{ height: '3rem' }}>
              <List>
                <ListItem>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Typography sx={{marginLeft: '-15px'}}>Gegründet</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography sx={{ fontWeight: 900, marginTop: '-16px', marginLeft: '-13px' }}>
                        März 2022
                      </Typography>
                    </Grid>
                  </Grid>
                </ListItem>
              </List>
            </Grid>
            <Grid item xs={12} sx={{ height: '3rem', marginTop: '5px' }}>
              <List>
                <ListItem>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Typography sx={{marginLeft: '-15px'}}>Founders</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography sx={{
                                    fontWeight: 900,
                                    whiteSpace: 'nowrap',
                                    marginTop: '-16px',
                                    marginLeft: '-14px'
                                  }}>
                        Janet Winkler & Debjit Chaudhuri
                      </Typography>
                    </Grid>
                  </Grid>
                </ListItem>
              </List>
            </Grid>
            <Grid item xs={12} sx={{ height: '3rem', marginTop: '5px' }}>
              <List>
                <ListItem>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Typography sx={{marginLeft: '-15px'}}>Standort</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography sx={{ fontWeight: 900, marginLeft: '-12px', marginTop: '-15px' }}>
                        Frankfurt am Main
                      </Typography>
                    </Grid>
                  </Grid>
                </ListItem>
              </List>
            </Grid>
          </Grid>
        </Box>
      }
      { ['mob'].includes(responsiveBreakpoint) &&
        <Grid container spacing={2} sx={{ padding: 2}}>
          <Grid item xs={12}>
            <Box>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography
                    variant="h3"
                    gutterBottom
                    sx={{
                      fontWeight: '900',
                      letterSpacing: '0.2px',
                      fontSize: { xs: '18px' },
                    }}
                  >
                    Fakten
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Divider
                    sx={{
                      borderBottomWidth: 2,
                      borderColor: '#bcddd2',
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12} sx={{height: '3rem'}}>
                  <List>
                    <ListItem>
                      <Box sx={{ display: 'flex', width: '100%', flexWrap: 'wrap', justifyContent: 'space-between'}}>
                        <Grid item xs={12}>
                          <Typography sx={{marginLeft: '-14PX'}}>Gegründet</Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography
                            sx={{
                              fontWeight: 900,
                              position: 'relative',
                              float: 'left',
                              right: '-1rem',
                              fontSize: '15px',
                              marginLeft: '-30px'
                            }}
                          >
                            März 2022
                          </Typography>
                        </Grid>
                      </Box>
                    </ListItem>
                  </List>
                </Grid>
                <Grid item xs={12} sx={{height: '3rem'}}>
                  <List>
                    <ListItem>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          width: '100%',
                          flexWrap: 'wrap',
                        }}
                      >
                        <Grid item xs={12}>
                          <Typography
                            sx={{
                              flex: '1',
                              minWidth: '120px',
                              position: 'relative',
                              float: 'left',
                              marginLeft: '-14PX',
                              marginTop: '8px'
                            }}
                          >
                            Founders
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography
                            sx={{
                              fontWeight: 900,
                              whiteSpace: 'nowrap',
                              flex: '2',
                              wordBreak: 'break-word',
                              position: 'relative',
                              right: '-1rem',
                              float: 'left',
                              fontSize: '15px',
                              marginLeft: '-30px'
                            }}
                          >
                            Janet Winkler & Debjit Chaudhuri
                          </Typography>
                        </Grid>
                      </Box>
                    </ListItem>
                  </List>
                </Grid>
                <Grid item xs={12} sx={{height: '3rem'}}>
                  <List>
                    <ListItem>
                      <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', flexWrap: 'wrap' }}>
                        <Grid item xs={12}>
                          <Typography sx={{marginLeft: '-14PX', marginTop: '13px'}}>Standort</Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography
                            sx={{
                              fontWeight: 900,
                              whiteSpace: 'nowrap',
                              position: 'relative',
                              right: '-1rem',
                              float: 'left',
                              fontSize: '15px',
                              marginLeft: '-30px'
                            }}
                          >
                            Frankfurt am Main
                          </Typography>
                        </Grid>
                      </Box>
                    </ListItem>
                  </List>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      }
    </>
  );
};

export default KeyFactsSection;