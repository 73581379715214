import { gql } from '@apollo/client'

export const USERS_QUERIES = gql`
    query UsersList(
        $userRole: String!
        $batchSize: Int!
        $keyword: String
        $step: Int!
        $industryIds: String
        $departmentIds: String
        $locationIds: String
        $experience: String
    ) {
        userCount(
            filter: $userRole
            keyword: $keyword
            industryIds: $industryIds
            departmentIds: $departmentIds
            locationIds: $locationIds
            experience: $experience
        )
        paginatedUsers(
            batchSize: $batchSize
            baseBatchSize: $batchSize
            step: $step
            filter: $userRole
            keyword: $keyword
            industryIds: $industryIds
            departmentIds: $departmentIds
            locationIds: $locationIds
            experience: $experience
        ) {
            id
            name
            createdAt
            lastLogoutAt
            userRole
            profileImage
            provider
            email
            candidate {
                id
                title
                states {
                    id
                    name
                }
                experience
                departments {
                    id
                    name
                }
                industries {
                    id
                    name
                }
                isActive
                candidateAvatar
                otherDepartment
                otherIndustry
            }
            company {
                id
                companyName
                isActive
                jobCount
                noOfEmployees
                companyLogoUrl
                states {
                    id
                    name
                }
            }
        }
    }
`

export const CANDIDATE_USERS_QUERIES = gql`
    query CandidatesList(
        $batchSize: Int!
        $keyword: String
        $step: Int!
        $industryIds: String
        $departmentIds: String
        $locationIds: String
        $experience: String
    ) {
        candidatesCount(
            keyword: $keyword
            industryIds: $industryIds
            departmentIds: $departmentIds
            locationIds: $locationIds
            experience: $experience
        )
        paginatedCandidates(
            batchSize: $batchSize
            baseBatchSize: $batchSize
            step: $step
            keyword: $keyword
            industryIds: $industryIds
            departmentIds: $departmentIds
            locationIds: $locationIds
            experience: $experience
        ) {
            id
            title
            states {
                id
                name
            }
            experience
            departments {
                id
                name
            }
            industries {
                id
                name
            }
            isActive
            otherDepartment
            otherIndustry
            email
            name
            candidateAvatar
            userId
            createdAt
            profileCompletionStatus
            personalEmailSubscribed
            user {
                id
                createdAt
                profileImage
                lastLogoutAt
                provider
                email
                name
            }
        }
    }
`
